<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=sort,-id"
    entity-name="办公室"
    add-btn="新增办公室"
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="{ name: 1 }"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="办公室名称">
        <a-input v-model="form.name" placeholder="输入办公室名称查询" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="办公室名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入办公室名称" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="排序">
        <a-input-number v-model="form.sort" placeholder="请输入" allowClear style="width: 100%;"></a-input-number>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "SpaceOffice",
  data() {
    return {
      url: "/admin/smart-office",
      columns: [{ title: "办公室名称", dataIndex: "name" }],
      rules: {
        name: [{ required: true, message: "请输入办公室名称", trigger: "blur" }],
      },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
